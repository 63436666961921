"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectTokens = void 0;
const selectTokens = (tks) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        tokens: (_b = (_a = tks === null || tks === void 0 ? void 0 : tks.tokens) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map((ed) => ed === null || ed === void 0 ? void 0 : ed.node),
        currentCursor: (_e = (_d = (_c = tks === null || tks === void 0 ? void 0 : tks.tokens) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.at(0)) === null || _e === void 0 ? void 0 : _e.cursor,
        hasNextPage: (_g = (_f = tks === null || tks === void 0 ? void 0 : tks.tokens) === null || _f === void 0 ? void 0 : _f.pageInfo) === null || _g === void 0 ? void 0 : _g.hasNextPage,
    };
};
exports.selectTokens = selectTokens;
