"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shortenHash = void 0;
function shortenHash(addr) {
    try {
        return `${addr.slice(0, 8)}-${addr.slice(addr.length - 6, addr.length)}`;
    }
    catch (err) {
        return "";
    }
}
exports.shortenHash = shortenHash;
