"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    primary: "var(--primary)",
    secondary: "var(--secondary)",
    black: "var(--black)",
    black95: "var(--black95)",
    black80: "var(--black80)",
    black60: "var(--black60)",
    black40: "var(--black40)",
    black20: "var(--black20)",
    black10: "var(--black10)",
    text01: "var(--text01)",
    textReverse: "var(--textReverse)",
    white: "var(--white)",
    error: "var(--error)",
    success: "var(--success)",
    warning: "var(--warning)",
    information: "var(--information)",
    nav01: "var(--nav01)",
    nav02: "var(--nav02)",
    nav03: "var(--nav03)"
};
