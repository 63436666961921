"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopyToClipboard = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
function CopyToClipboard({ value, reason }) {
    const [copied, setCopied] = (0, react_1.useState)(false);
    const text = copied ? "Copied!" : reason;
    const ref = (0, react_1.createRef)();
    (0, react_1.useEffect)(() => {
        var _a, _b;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener("mouseleave", () => {
            setCopied(false);
        });
        (_b = ref.current) === null || _b === void 0 ? void 0 : _b.addEventListener("touchcancel", () => {
            setCopied(false);
        });
    }, [ref]);
    return (React.createElement("span", { "aria-label": text, "data-clipboard-text": value, className: "btn-copy-icon btn-copy-icon-small btn-copy-icon-custom btn-copy-icon-no-borders i-tooltip-2", "data-placement": "top", "data-toggle": "tooltip", title: text, style: {}, ref: ref, onClick: () => {
            setCopied(true);
            navigator.clipboard.writeText(value);
        } },
        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32.5 32.5", width: 32, height: 32 },
            React.createElement("path", { fillRule: "evenodd", d: "M23.5 20.5a1 1 0 0 1-1-1v-9h-9a1 1 0 0 1 0-2h10a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zm-3-7v10a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zm-2 1h-8v8h8v-8z" }))));
}
exports.CopyToClipboard = CopyToClipboard;
