"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlksTableContainer = void 0;
const react_1 = __importStar(require("react"));
const use_query_blocks_1 = require("@/shared/blks-table-container/hooks/use-query-blocks");
const blk_list_1 = require("@/shared/explorer-components/blk-list");
const pagination_1 = require("@/shared/explorer-components/pagination");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("onefx/lib/react-router");
const iso_i18n_1 = require("onefx/lib/iso-i18n");
const paginations_1 = require("@/shared/common/functions/paginations");
const BlksTableContainer = () => {
    return (react_1.default.createElement("main", { className: "js-ad-dependant-pt pt-5" },
        react_1.default.createElement("p", { className: "alert alert-info", role: "alert" }),
        react_1.default.createElement("p", { className: "alert alert-danger", role: "alert" }),
        react_1.default.createElement("section", { className: "container", "data-page": "block-list" },
            react_1.default.createElement("div", { className: "ad mb-3", style: { display: "none" } },
                react_1.default.createElement("span", { className: "ad-prefix" }),
                ":",
                " ",
                react_1.default.createElement("img", { className: "ad-img-url", width: 20, height: 20 }),
                " ",
                react_1.default.createElement("b", null,
                    react_1.default.createElement("span", { className: "ad-name" })),
                " ",
                "- ",
                react_1.default.createElement("span", { className: "ad-short-description" }),
                " ",
                react_1.default.createElement("a", { className: "ad-url" },
                    react_1.default.createElement("b", null,
                        react_1.default.createElement("span", { className: "ad-cta-button" })))),
            react_1.default.createElement("div", { className: "card" },
                react_1.default.createElement("div", { className: "card-body", "data-async-listing": "/poa/core/blocks" },
                    react_1.default.createElement("div", { "data-selector": "channel-disconnected-message", style: { display: "none" } },
                        react_1.default.createElement("div", { "data-selector": "reload-button", className: "alert alert-danger", style: { padding: "0.75rem 0rem", cursor: "pointer" } },
                            react_1.default.createElement("span", { className: "alert alert-danger" }, "Connection Lost, click to load newer blocks"))),
                    react_1.default.createElement("h1", { className: "card-title list-title-description" }, (0, iso_i18n_1.t)("nav.blocks")),
                    react_1.default.createElement(TableWithPagination, null))))));
};
exports.BlksTableContainer = BlksTableContainer;
const TableWithPagination = () => {
    var _a, _b;
    const location = (0, react_router_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    const search = new URLSearchParams(location.search);
    const initialPage = Number(search.get("page")) || 1;
    const [curPage, setCurPage] = (0, react_1.useState)(initialPage);
    const pageSize = 20;
    const setCurPageWithSideEffect = (p) => {
        setCurPage(p);
        history.push({ search: `?page=${p}` });
    };
    const { loading, data, error, refetch } = (0, use_query_blocks_1.useQueryBlocks)({ first: pageSize, after: (curPage - 1) * pageSize }, {});
    if (loading) {
        // TODO(dora)
        return react_1.default.createElement(react_1.default.Fragment, null);
    }
    const blks = (_b = (_a = data === null || data === void 0 ? void 0 : data.blocks) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map((e) => e === null || e === void 0 ? void 0 : e.node);
    const numPage = (0, paginations_1.paginationProcessTotalNumPage)(data === null || data === void 0 ? void 0 : data.blocks);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        error && (react_1.default.createElement("button", { onClick: refetch, "data-error-message": true, className: "alert alert-danger col-12 text-left" },
            react_1.default.createElement("span", { className: "alert-link" }, (0, iso_i18n_1.t)("info.err")))),
        blks && (blks === null || blks === void 0 ? void 0 : blks.length) > 0 ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { className: "list-top-pagination-container-wrapper" },
                react_1.default.createElement(pagination_1.Pagination, { setCurPage: setCurPageWithSideEffect, curPage: curPage, numPages: numPage, position: "top" })),
            react_1.default.createElement(blk_list_1.BlkList, { blks: blks }),
            react_1.default.createElement("div", { className: "list-bottom-pagination-container-wrapper" },
                react_1.default.createElement(pagination_1.Pagination, { position: "bottom", setCurPage: setCurPageWithSideEffect, curPage: curPage, numPages: numPage })))) : (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { "data-empty-response-message": true },
                react_1.default.createElement("span", null, "There are no blocks."))))));
};
