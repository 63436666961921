"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryTx = void 0;
const client_1 = require("@apollo/client");
const query_tx_1 = require("@/shared/tx-details-container/data/query-tx");
const useQueryTx = ({ hash }) => {
    const { data, loading, error, refetch } = (0, client_1.useQuery)(query_tx_1.queryTx, {
        ssr: false,
        variables: {
            hash,
        },
    });
    return {
        data,
        loading,
        error,
        refetch,
    };
};
exports.useQueryTx = useQueryTx;
