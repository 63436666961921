"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokensContainer = void 0;
const react_1 = __importStar(require("react"));
const use_query_tokens_1 = require("@/shared/token-container/hooks/use-query-tokens");
const select_tokens_1 = require("@/shared/token-container/selectors/select-tokens");
const asset_url_1 = require("onefx/lib/asset-url");
const shorten_hash_1 = require("@/shared/common/shorten-hash");
const react_router_dom_1 = require("react-router-dom");
const react_router_1 = require("onefx/lib/react-router");
const TokensContainer = () => {
    const history = (0, react_router_dom_1.useHistory)();
    const location = (0, react_router_1.useLocation)();
    const searchParams = new URLSearchParams(location.search);
    const querySymbol = searchParams.get("symbol");
    const [symbol, setSymbol] = (0, react_1.useState)(String(querySymbol !== null && querySymbol !== void 0 ? querySymbol : "").toUpperCase());
    const updateSymbol = (s) => {
        // Create a new URLSearchParams object
        const p = new URLSearchParams(location.search);
        // Set the new query value
        p.set("symbol", s);
        // Update the URL with the new query string
        history.push({ search: p.toString() });
        setSymbol(s);
    };
    const { tokensData, tokensFetchMore } = (0, use_query_tokens_1.useQueryTokens)(symbol);
    const { tokens, currentCursor, hasNextPage } = (0, select_tokens_1.selectTokens)(tokensData);
    const handleKeyUp = async (event) => {
        if (event.key === "Enter") {
            const uppercase = String(event.target.value).toUpperCase();
            updateSymbol(uppercase);
            await tokensFetchMore("0", uppercase);
        }
    };
    return (react_1.default.createElement("main", { className: "pt-4" },
        react_1.default.createElement("p", { className: "alert alert-info", role: "alert" }),
        react_1.default.createElement("p", { className: "alert alert-danger", role: "alert" }),
        react_1.default.createElement("section", { className: "container", "data-page": "tokens", "data-chain-id": 10, "data-display-token-icons": "false" },
            react_1.default.createElement("div", { className: "ad mb-3", style: { display: "none" } },
                react_1.default.createElement("span", { className: "ad-prefix" }),
                ":",
                " ",
                react_1.default.createElement("img", { className: "ad-img-url", width: 20, height: 20 }),
                " ",
                react_1.default.createElement("b", null,
                    react_1.default.createElement("span", { className: "ad-name" })),
                " ",
                "- ",
                react_1.default.createElement("span", { className: "ad-short-description" }),
                " ",
                react_1.default.createElement("a", { className: "ad-url" },
                    react_1.default.createElement("b", null,
                        react_1.default.createElement("span", { className: "ad-cta-button" })))),
            react_1.default.createElement("div", { className: "card" },
                react_1.default.createElement("div", { className: "card-body", "data-async-load": "", "data-async-listing": "/optimism/mainnet/tokens", "data-no-self-calls": "" },
                    react_1.default.createElement("h1", { className: "card-title list-title-description" }, "Tokens"),
                    react_1.default.createElement("div", { className: "list-top-pagination-container-wrapper tokens-list-search-input-outer-container d-flex", style: { float: "right" } },
                        react_1.default.createElement("label", { className: "tokens-list-search-input-container tokens mr-3" },
                            react_1.default.createElement("input", { "data-search-field": "", className: "form-control tokens-list-search-input search-input", type: "text", name: "filter", defaultValue: symbol, placeholder: "Token symbol", id: "search-text-input", onKeyUp: handleKeyUp })),
                        react_1.default.createElement("div", { className: "pagination-container position-top ", "data-pagination-container": "" },
                            react_1.default.createElement("ul", { className: "pagination" },
                                react_1.default.createElement("li", { className: "page-item" },
                                    react_1.default.createElement("a", { className: "page-link no-hover", href: "", "data-page-number": "" },
                                        "Page ",
                                        currentCursor)),
                                hasNextPage && (react_1.default.createElement("li", { className: "page-item" },
                                    react_1.default.createElement("a", { className: "page-link", href: "#", "data-next-page-button": "", onClick: () => {
                                            var _a, _b;
                                            return tokensFetchMore((_b = (_a = tokensData === null || tokensData === void 0 ? void 0 : tokensData.tokens) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.endCursor, symbol);
                                        } },
                                        react_1.default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 6, height: 10 },
                                            react_1.default.createElement("path", { fillRule: "evenodd", d: "M5.715 5.715c-.064.064-.141.102-.217.144L1.642 9.715A.959.959 0 1 1 .285 8.358L3.642 5 .285 1.642A.959.959 0 1 1 1.642.285L5.498 4.14c.075.043.153.081.217.145A.949.949 0 0 1 5.989 5a.949.949 0 0 1-.274.715z" })))))))),
                    react_1.default.createElement("div", { className: "addresses-table-container" },
                        react_1.default.createElement("div", { className: "stakes-table-container" },
                            react_1.default.createElement("table", null,
                                react_1.default.createElement("thead", null,
                                    react_1.default.createElement("tr", null,
                                        react_1.default.createElement("th", { className: "stakes-table-th" },
                                            react_1.default.createElement("div", { className: "stakes-table-th-content" }, "\u00A0")),
                                        react_1.default.createElement("th", { className: "stakes-table-th" },
                                            react_1.default.createElement("div", null, "\u00A0")),
                                        react_1.default.createElement("th", { className: "stakes-table-th" },
                                            react_1.default.createElement("div", { className: "stakes-table-th-content" }, "Token")),
                                        react_1.default.createElement("th", { className: "stakes-table-th" },
                                            react_1.default.createElement("div", { className: "stakes-table-th-content" }, "Address")),
                                        react_1.default.createElement("th", { className: "stakes-table-th" },
                                            react_1.default.createElement("div", { className: "stakes-table-th-content" }, "Total Supply")))),
                                react_1.default.createElement("tbody", { "data-items": "", "data-selector": "top-tokens-list" }, tokens === null || tokens === void 0 ? void 0 : tokens.map((t) => (react_1.default.createElement("tr", { key: t === null || t === void 0 ? void 0 : t.contractAddress },
                                    react_1.default.createElement("td", { className: "stakes-td" },
                                        react_1.default.createElement("span", { className: "color-lighten" })),
                                    react_1.default.createElement("td", { className: "token-icon" }),
                                    react_1.default.createElement("td", { className: "stakes-td" },
                                        react_1.default.createElement("a", { className: "text-truncate", "data-test": "token_link", href: (0, asset_url_1.assetURL)(`address/${t === null || t === void 0 ? void 0 : t.contractAddress}`) }, t === null || t === void 0 ? void 0 :
                                            t.name,
                                            " (", t === null || t === void 0 ? void 0 :
                                            t.symbol,
                                            ")")),
                                    react_1.default.createElement("td", { className: "stakes-td" },
                                        react_1.default.createElement("a", { "data-test": "address_hash_link", href: (0, asset_url_1.assetURL)(`address/${t === null || t === void 0 ? void 0 : t.contractAddress}`) },
                                            react_1.default.createElement("span", { className: "contract-address" },
                                                react_1.default.createElement("span", { "data-toggle": "tooltip", "data-placement": "top", title: "", "data-custom-class": "" },
                                                    react_1.default.createElement("span", { className: "d-none d-md-none d-lg-inline d-xl-inline" }, t === null || t === void 0 ? void 0 : t.name),
                                                    react_1.default.createElement("span", { className: "d-inline d-md-inline d-lg-none d-xl-none" }, t === null || t === void 0 ? void 0 : t.name),
                                                    react_1.default.createElement("span", null,
                                                        " ",
                                                        "(",
                                                        (0, shorten_hash_1.shortenHash)(t === null || t === void 0 ? void 0 : t.contractAddress),
                                                        ")"))))),
                                    react_1.default.createElement("td", { className: "stakes-td" },
                                        react_1.default.createElement("span", { "data-test": "token_supply" }, Number(t === null || t === void 0 ? void 0 : t.totalSupply).toLocaleString()),
                                        " ", t === null || t === void 0 ? void 0 :
                                        t.symbol)))))))),
                    react_1.default.createElement("div", { className: "pagination-container  position-bottom", "data-pagination-container": "" },
                        react_1.default.createElement("ul", { className: "pagination" },
                            react_1.default.createElement("li", { className: "page-item" },
                                react_1.default.createElement("a", { className: "page-link no-hover", href: "", "data-page-number": "" },
                                    "Page ",
                                    currentCursor)),
                            hasNextPage && (react_1.default.createElement("li", { className: "page-item" },
                                react_1.default.createElement("a", { className: "page-link", href: "#", onClick: () => {
                                        var _a, _b;
                                        return tokensFetchMore((_b = (_a = tokensData === null || tokensData === void 0 ? void 0 : tokensData.tokens) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.endCursor, symbol);
                                    }, "data-next-page-button": "" },
                                    react_1.default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 6, height: 10 },
                                        react_1.default.createElement("path", { fillRule: "evenodd", d: "M5.715 5.715c-.064.064-.141.102-.217.144L1.642 9.715A.959.959 0 1 1 .285 8.358L3.642 5 .285 1.642A.959.959 0 1 1 1.642.285L5.498 4.14c.075.043.153.081.217.145A.949.949 0 0 1 5.989 5a.949.949 0 0 1-.274.715z" }))))))))))));
};
exports.TokensContainer = TokensContainer;
