"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = exports.FOOTER_ABOVE = exports.FOOTER_HEIGHT = void 0;
const react_1 = __importStar(require("react"));
const asset_url_1 = require("onefx/lib/asset-url");
const use_chain_config_1 = require("@/shared/common/use-chain-config");
const iso_i18n_1 = require("onefx/lib/iso-i18n");
const multi_chain_dropdown_1 = require("@/shared/home/components/multi-chain-dropdown");
const github_tmpl_url_1 = require("@/shared/common/github-tmpl-url");
const top_bar_1 = require("./top-bar");
const addChainToMM = require("../blockscout-web-js-lib/add_chain_to_mm");
exports.FOOTER_HEIGHT = 0;
exports.FOOTER_ABOVE = {
    minHeight: `calc(100vh - ${exports.FOOTER_HEIGHT + top_bar_1.TOP_BAR_HEIGHT}px)`,
};
function Footer() {
    const [mmAdded, setMmAdded] = (0, react_1.useState)(false);
    const chainConfig = (0, use_chain_config_1.useChainConfig)();
    const [githubTmplUrl, setGithubTmplUrl] = (0, react_1.useState)("");
    (0, react_1.useEffect)(() => {
        setGithubTmplUrl((0, github_tmpl_url_1.getGithubTmplUrl)({ symbol: chainConfig.symbol }));
    }, [chainConfig.symbol]);
    return (react_1.default.createElement("footer", { className: "footer" },
        react_1.default.createElement("div", { className: "footer-body container" },
            react_1.default.createElement("div", { className: "row footer-logo-row" },
                react_1.default.createElement("div", { className: "col-md-12" },
                    react_1.default.createElement("a", { className: "footer-brand", href: (0, asset_url_1.assetURL)("") },
                        react_1.default.createElement("img", { className: "footer-logo", src: (0, asset_url_1.assetURL)("favicon.png"), alt: "BMO" })))),
            react_1.default.createElement("div", { className: "row" },
                react_1.default.createElement("div", { className: "col-xs-12 col-lg-3" },
                    react_1.default.createElement("p", { className: "footer-info-text" }, (0, iso_i18n_1.t)("meta.description")),
                    react_1.default.createElement("div", { className: "footer-social-icons" },
                        react_1.default.createElement("a", { href: "https://github.com/stargately/blockroma", rel: "noreferrer", target: "_blank", className: "footer-social-icon", title: "Github" },
                            react_1.default.createElement("div", { className: "footer-social-icon-container fontawesome-icon github" })),
                        react_1.default.createElement("a", { href: "https://www.twitter.com/puncsky/", rel: "noreferrer", target: "_blank", className: "footer-social-icon", title: "Twitter" },
                            react_1.default.createElement("div", { className: "footer-social-icon-container fontawesome-icon twitter" })),
                        react_1.default.createElement("a", { href: "https://t.me/system_design_and_architecture", rel: "noreferrer", target: "_blank", className: "footer-social-icon", title: "Telegram" },
                            react_1.default.createElement("div", { className: "footer-social-icon-container fontawesome-icon telegram" })))),
                react_1.default.createElement("div", { className: "col-xs-12 col-md-4 col-lg-3 footer-list" },
                    react_1.default.createElement("h3", null, "Blockroma"),
                    react_1.default.createElement("ul", null,
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("a", { href: githubTmplUrl, rel: "noreferrer", className: "footer-link", target: "_blank" }, "Submit an Issue")),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("a", { href: "https://github.com/stargately/blockroma", rel: "noreferrer", className: "footer-link" }, "Contribute")),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("a", { href: "https://discord.gg/Pb5YbK3ykN", rel: "noreferrer", className: "footer-link" }, "Chat (#blockroma)")),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("a", { onClick: async () => {
                                    await setMmAdded(await addChainToMM(chainConfig));
                                }, className: "footer-link js-btn-add-chain-to-mm btn-add-chain-to-mm in-footer", style: { cursor: "pointer" } }, mmAdded
                                ? `${chainConfig.symbol} Added to MetaMask`
                                : `Add ${chainConfig.symbol} to MetaMask`)))),
                react_1.default.createElement("div", { className: "col-xs-12 col-md-4 col-lg-3 footer-list" },
                    react_1.default.createElement("h3", null, "Main Networks"),
                    react_1.default.createElement("ul", null, multi_chain_dropdown_1.chainSwitchOpts.mainnets.map((it) => (react_1.default.createElement("li", { key: it[0] },
                        react_1.default.createElement("a", { href: it[0], rel: "norefferer", className: "footer-link" },
                            " ",
                            it[1],
                            " ")))))),
                react_1.default.createElement("div", { className: "col-xs-12 col-md-4 col-lg-3 footer-list" },
                    react_1.default.createElement("h3", null, "Test Networks"),
                    react_1.default.createElement("ul", null, multi_chain_dropdown_1.chainSwitchOpts.testnets.map((it) => (react_1.default.createElement("li", { key: it[0] },
                        react_1.default.createElement("a", { href: it[0], rel: "norefferer", className: "footer-link" },
                            " ",
                            it[1],
                            " "))))))))));
}
exports.Footer = Footer;
