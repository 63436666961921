"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryBlock = void 0;
const client_1 = require("@apollo/client");
const quries_1 = require("@/shared/block-details-container/data/quries");
const useQueryBlock = (blockNumber) => {
    const { loading, data, error, refetch } = (0, client_1.useQuery)(quries_1.queryBlock, {
        ssr: false,
        variables: { blockNumber },
    });
    return { loading, data, error, refetch };
};
exports.useQueryBlock = useQueryBlock;
