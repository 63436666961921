"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTokenDetails = void 0;
const client_1 = require("@apollo/client");
const queries_1 = require("@/shared/token-transfer-container/data/queries");
const useTokenDetails = (tokenContractAddressHash) => {
    const { data, loading, error, refetch } = (0, client_1.useQuery)(queries_1.queryTokenDetails, {
        ssr: false,
        skip: !tokenContractAddressHash,
        variables: {
            tokenContractAddressHash,
        },
    });
    return {
        tokenDetailsData: data,
        tokenDetailsLoading: loading,
        tokenDetailsError: error,
        tokenDetailsRefetch: refetch,
    };
};
exports.useTokenDetails = useTokenDetails;
