"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actionSetTheme = exports.baseReducer = void 0;
const theme_provider_1 = require("onefx/lib/styletron-react/theme-provider");
const storeTheme = (newTheme) => {
    try {
        localStorage.setItem("theme", newTheme);
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
function baseReducer(initialState = { themeCode: theme_provider_1.defaultThemeCode }, action) {
    if (action.type === "SET_THEME") {
        const themeCode = action.payload === "light" ? "light" : "dark";
        window.document &&
            window.document.documentElement.setAttribute("data-theme", themeCode);
        if (themeCode === "dark") {
            window.document.documentElement.classList.add("dark-theme-applied");
        }
        else {
            window.document.documentElement.classList.remove("dark-theme-applied");
        }
        storeTheme(themeCode);
        return {
            ...initialState,
            themeCode,
        };
    }
    if (!initialState.themeCode) {
        initialState.themeCode = theme_provider_1.defaultThemeCode;
    }
    return initialState;
}
exports.baseReducer = baseReducer;
function actionSetTheme(themeCode) {
    return {
        type: "SET_THEME",
        payload: themeCode,
    };
}
exports.actionSetTheme = actionSetTheme;
