"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryTokenDetails = exports.queryTokenTransfer = void 0;
const client_1 = require("@apollo/client");
exports.queryTokenTransfer = (0, client_1.gql) `
  query TokenTransfer($transactionHash: Buffer) {
    tokenTransfer(transactionHash: $transactionHash) {
      edges {
        node {
          id
          transactionHash
          logIndex
          fromAddress
          toAddress
          amount
          amountWithDecimals
          tokenId
          tokenContractAddress
          block
          blockNumber
          amounts
          tokenIds
          createdAt
          updatedAt
          type
        }
      }
    }
  }
`;
exports.queryTokenDetails = (0, client_1.gql) `
  query TokenDetails($tokenContractAddressHash: Buffer!) {
    token(tokenContractAddressHash: $tokenContractAddressHash) {
      name
      symbol
      totalSupply
      decimals
      type
      contractAddress
      skipMetadata
    }
  }
`;
