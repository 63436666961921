"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlkTile = void 0;
const React = __importStar(require("react"));
const shorten_hash_1 = require("@/shared/common/shorten-hash");
const ticking_ts_1 = require("@/shared/explorer-components/ticking-ts");
const iso_i18n_1 = require("onefx/lib/iso-i18n");
const asset_url_1 = require("onefx/lib/asset-url");
function BlkTile({ blk }) {
    if (!blk) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: "col-lg-3 fade-up-blocks-chain", "data-selector": "chain-block", "data-block-number": blk.number },
        React.createElement("div", { className: "tile tile-type-block n-p d-flex flex-column" },
            React.createElement("a", { className: "tile-title", "data-selector": "block-number", href: (0, asset_url_1.assetURL)(`block/${blk.number}`) }, blk.number),
            React.createElement("div", { className: "tile-bottom-contents" },
                React.createElement("div", { className: "tile-transactions" },
                    React.createElement("span", { className: "mr-2" },
                        blk.numTxs,
                        " ",
                        (0, iso_i18n_1.t)("nav.txs")),
                    React.createElement(ticking_ts_1.TickingTs, { className: "text-nowrap", timestamp: blk.timestamp })),
                React.createElement("div", { className: "text-truncate" },
                    "Validator",
                    " ",
                    React.createElement("a", { "data-test": "address_hash_link", href: (0, asset_url_1.assetURL)(`address/${blk.miner}`) },
                        React.createElement("span", { "data-address-hash": blk.miner },
                            React.createElement("span", { "data-toggle": "tooltip", "data-placement": "top", title: blk.miner, "data-custom-class": "miner-address-tooltip" },
                                React.createElement("span", null, (0, shorten_hash_1.shortenHash)(blk.miner))))))))));
}
exports.BlkTile = BlkTile;
