"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressDetailsContainer = void 0;
const react_1 = __importStar(require("react"));
const react_router_1 = require("react-router");
const address_transactions_container_1 = require("@/shared/address-details-container/address-transactions-container");
const use_chain_config_1 = require("@/shared/common/use-chain-config");
const iso_i18n_1 = require("onefx/lib/iso-i18n");
const asset_url_1 = require("onefx/lib/asset-url");
const use_get_addr_details_1 = require("@/shared/address-details-container/hooks/use-get-addr-details");
const qr_modal_1 = require("./components/qr-modal");
const copy_address_1 = require("../explorer-components/copy-address");
const AddressDetailsContainer = () => {
    var _a, _b, _c, _d, _e, _f;
    const chainConfig = (0, use_chain_config_1.useChainConfig)();
    const params = (0, react_router_1.useParams)();
    const { addressHash: rawAddressHash } = params;
    const addressHash = rawAddressHash.toLowerCase();
    const [qrModalOpen, setQrModalOpen] = (0, react_1.useState)(false);
    const { data, loading, error, refetch } = (0, use_get_addr_details_1.useGetAddrDetails)({
        hash: addressHash,
    });
    if (loading) {
        return react_1.default.createElement(react_1.default.Fragment, null);
    }
    if (error && ((_b = (_a = error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.code) !== "NOT_FOUND") {
        // TODO(dora):
        return react_1.default.createElement("button", { onClick: refetch }, "error");
    }
    const addr = data === null || data === void 0 ? void 0 : data.address;
    if (!addr) {
        return react_1.default.createElement(react_1.default.Fragment, null);
    }
    return (react_1.default.createElement("main", { className: "js-ad-dependant-pt pt-5" },
        react_1.default.createElement("p", { className: "alert alert-info", role: "alert" }),
        react_1.default.createElement("p", { className: "alert alert-danger", role: "alert" }),
        react_1.default.createElement("section", { className: "container" },
            react_1.default.createElement("section", { className: "address-overview", "data-page": "address-details", "data-page-address-hash": addressHash, "data-async-counters": `/address-counters?id=${addressHash}` },
                react_1.default.createElement("div", { className: "row js-ad-dependant-mb-2 js-ad-dependant-mb-5-reverse mb-2" },
                    react_1.default.createElement("div", { className: "col-md-12 js-ad-dependant-mb-2 mb-2" },
                        react_1.default.createElement("div", { className: "card js-ad-dependant-mb-2 mb-2" },
                            react_1.default.createElement("div", { className: "card-body fs-14", style: { lineHeight: "31px" } },
                                react_1.default.createElement("h1", { className: "card-title lg-card-title mb-2-desktop" },
                                    react_1.default.createElement("div", { className: "title-with-label" }, "Address Details"),
                                    react_1.default.createElement("span", { className: "overview-title-buttons float-right" },
                                        react_1.default.createElement(copy_address_1.CopyAddress, { addressHash: addr.hash }),
                                        react_1.default.createElement("span", { className: "overview-title-item", "data-target": "#qrModal", "data-toggle": "modal", onClick: () => setQrModalOpen(true) },
                                            react_1.default.createElement("span", { className: "btn-qr-icon i-tooltip-2", "data-toggle": "tooltip", "data-placement": "top", title: "QR Code", "aria-label": "Show QR Code" },
                                                react_1.default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32.5 32.5", width: 32, height: 32 },
                                                    react_1.default.createElement("path", { fillRule: "evenodd", d: "M22.5 24.5v-2h2v2h-2zm-1-4v-1h1v1h-1zm1-3h2v2h-2v-2zm1-2h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-1-5h-3v3h3v-3zm-8 14h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-1-5h-3v3h3v-3zm1-4h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-1-5h-3v3h3v-3zm6 9h-2v-2h2v2zm1 1h-1v-1h1v1zm0 1v1h-1v-1h1zm-1 3h-2v-2h2v2z" })))))),
                                react_1.default.createElement("h3", { className: "address-detail-hash-title mb-4 ", "data-test": "address_detail_hash" }, addressHash),
                                react_1.default.createElement("dl", { className: "row" },
                                    react_1.default.createElement("dt", { className: "col-sm-4 col-md-4 col-lg-3 text-muted" },
                                        react_1.default.createElement("span", { className: "i-tooltip-2 ", "data-boundary": "window", "data-container": "body", "data-html": "true", "data-placement": "top", "data-toggle": "tooltip", title: `Address balance in ${chainConfig.symbol} (doesn't include ERC20, ERC721, ERC1155 tokens).` },
                                            react_1.default.createElement("i", { className: "fa-solid fa-info-circle" }),
                                            " "),
                                        "Balance"),
                                    react_1.default.createElement("dd", { className: "col-sm-8 col-md-8 col-lg-9", "data-test": "address_balance" },
                                        addr.fetchedCoinBalanceWithDecimal,
                                        " ",
                                        chainConfig.symbol)),
                                react_1.default.createElement("dl", { className: "row", "data-test": "outside_of_dropdown" },
                                    react_1.default.createElement("dt", { className: "col-sm-4 col-md-4 col-lg-3 text-muted" },
                                        react_1.default.createElement("span", { className: "i-tooltip-2 ", "data-boundary": "window", "data-container": "body", "data-html": "true", "data-placement": "top", "data-toggle": "tooltip", title: "All tokens in the account and total value." },
                                            react_1.default.createElement("i", { className: "fa-solid fa-info-circle" }),
                                            " "),
                                        "Tokens"),
                                    react_1.default.createElement("dd", { className: "col-sm-8 col-md-8 col-lg-9", "data-test": "address_tokens", "data-selector": "balance-card" },
                                        react_1.default.createElement("div", { className: "address-current-balance", "data-token-balance-dropdown": true, "data-api_path": `/address/${addressHash}/token-balances` },
                                            react_1.default.createElement("div", { className: "d-flex" },
                                                react_1.default.createElement("span", { "data-tokens-count": true, style: { lineHeight: "31px" } }, "0 tokens"),
                                                react_1.default.createElement("div", { className: "dropdown-menu dropdown-menu-right token-balance-dropdown p-0", "aria-labelledby": "dropdown-tokens" },
                                                    react_1.default.createElement("div", { "data-dropdown-items": true, className: "dropdown-items" },
                                                        react_1.default.createElement("div", { className: "position-relative" },
                                                            react_1.default.createElement("svg", { className: "position-absolute dropdown-search-icon", viewBox: "0 0 16 17", xmlns: "http://www.w3.org/2000/svg", width: 16, height: 17 },
                                                                react_1.default.createElement("path", { fill: "#7DD79F", fillRule: "evenodd", d: "M15.713 15.727a.982.982 0 0 1-1.388 0l-2.289-2.29C10.773 14.403 9.213 15 7.5 15A7.5 7.5 0 1 1 15 7.5c0 1.719-.602 3.284-1.575 4.55l2.288 2.288a.983.983 0 0 1 0 1.389zM7.5 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 1 0 0-11z" })),
                                                            react_1.default.createElement("input", { className: "w-100 dropdown-search-field", id: "token_search_name", name: "token_search[name]", placeholder: "Search tokens", type: "text", "data-filter-dropdown-tokens": true })))))))),
                                react_1.default.createElement("dl", { className: "row address-transactions-count-item" },
                                    react_1.default.createElement("dt", { className: "col-sm-4 col-md-4 col-lg-3 text-muted" },
                                        react_1.default.createElement("span", { className: "i-tooltip-2 ", "data-boundary": "window", "data-container": "body", "data-html": "true", "data-placement": "top", "data-toggle": "tooltip", title: "Number of transactions related to this address." },
                                            react_1.default.createElement("i", { className: "fa-solid fa-info-circle" }),
                                            " "),
                                        (0, iso_i18n_1.t)("nav.txs")),
                                    react_1.default.createElement("dd", { className: "col-sm-8 col-md-8 col-lg-9", "data-test": "address_transaction_count" },
                                        react_1.default.createElement("a", { href: "#txs", className: "page-link bs-label large btn-no-border-link-to-tems", "data-selector": "transaction-count" }, (_c = addr.numTxs) !== null && _c !== void 0 ? _c : 0,
                                            " ",
                                            (0, iso_i18n_1.t)("nav.txs")))),
                                react_1.default.createElement("dl", { className: "row address-transfers-count-item" },
                                    react_1.default.createElement("dt", { className: "col-sm-4 col-md-4 col-lg-3 text-muted" },
                                        react_1.default.createElement("span", { className: "i-tooltip-2 ", "data-boundary": "window", "data-container": "body", "data-html": "true", "data-placement": "top", "data-toggle": "tooltip", title: "Number of transfers to/from this address." },
                                            react_1.default.createElement("i", { className: "fa-solid fa-info-circle" }),
                                            " "),
                                        "Transfers"),
                                    react_1.default.createElement("dd", { className: "col-sm-8 col-md-8 col-lg-9", "data-test": "address_transfer_count" },
                                        react_1.default.createElement("a", { href: (0, asset_url_1.assetURL)(`address/${addressHash}/token-transfers#transfers`), className: "page-link bs-label large btn-no-border-link-to-tems", "data-selector": "transfer-count" }, "0 Transfers"))),
                                react_1.default.createElement("dl", { className: "row address-nonce-item" },
                                    react_1.default.createElement("dt", { className: "col-sm-4 col-md-4 col-lg-3 text-muted" },
                                        react_1.default.createElement("span", { className: "i-tooltip-2 ", "data-boundary": "window", "data-container": "body", "data-html": "true", "data-placement": "top", "data-toggle": "tooltip", title: "Nonce is transaction count of the account." },
                                            react_1.default.createElement("i", { className: "fa-solid fa-info-circle" }),
                                            " "),
                                        "Nonce"),
                                    react_1.default.createElement("dd", { className: "col-sm-8 col-md-8 col-lg-9", "data-test": "address_transaction_count" },
                                        react_1.default.createElement("span", { "data-selector": "Nonce" }, (_d = addr.nonce) !== null && _d !== void 0 ? _d : 0))),
                                react_1.default.createElement("dl", { className: "row address-gas-used-item" },
                                    react_1.default.createElement("dt", { className: "col-sm-4 col-md-4 col-lg-3 text-muted" },
                                        react_1.default.createElement("span", { className: "i-tooltip-2 ", "data-boundary": "window", "data-container": "body", "data-html": "true", "data-placement": "top", "data-toggle": "tooltip", title: "Gas used by the address." },
                                            react_1.default.createElement("i", { className: "fa-solid fa-info-circle" }),
                                            " "),
                                        "Gas Used"),
                                    react_1.default.createElement("dd", { className: "col-sm-8 col-md-8 col-lg-9", "data-test": "address_gas_used" },
                                        react_1.default.createElement("span", { "data-selector": "gas-usage-count" }, (_e = addr.gasUsed) !== null && _e !== void 0 ? _e : 0))),
                                react_1.default.createElement("dl", { className: "row" },
                                    react_1.default.createElement("dt", { className: "col-sm-4 col-md-4 col-lg-3 text-muted" },
                                        react_1.default.createElement("span", { className: "i-tooltip-2 ", "data-boundary": "window", "data-container": "body", "data-html": "true", "data-placement": "top", "data-toggle": "tooltip", title: "Block number in which the address was updated." },
                                            react_1.default.createElement("i", { className: "fa-solid fa-info-circle" }),
                                            " "),
                                        "Last Balance Update"),
                                    react_1.default.createElement("dd", { className: "col-sm-8 col-md-8 col-lg-9", "data-test": "address_last_balance_update" },
                                        react_1.default.createElement("a", { className: "tile-title-lg", href: (0, asset_url_1.assetURL)(`block/${addr.fetchedCoinBalanceBlockNumber}`) }, (_f = addr.fetchedCoinBalanceBlockNumber) !== null && _f !== void 0 ? _f : 0))),
                                react_1.default.createElement("dl", { className: "row address-validation-count-item", style: { display: "none" } },
                                    react_1.default.createElement("dt", { className: "col-sm-4 col-md-4 col-lg-3 text-muted" },
                                        react_1.default.createElement("span", { className: "i-tooltip-2 ", "data-boundary": "window", "data-container": "body", "data-html": "true", "data-placement": "top", "data-toggle": "tooltip", title: "Number of blocks validated by this validator." },
                                            react_1.default.createElement("i", { className: "fa-solid fa-info-circle" }),
                                            " "),
                                        "Blocks Validated"),
                                    react_1.default.createElement("dd", { className: "col-sm-8 col-md-8 col-lg-9", "data-test": "address_blocks_validated" },
                                        react_1.default.createElement("span", { "data-selector": "validation-count" })))))))),
            react_1.default.createElement(qr_modal_1.QrModal, { addressHash: addressHash, open: qrModalOpen, hashQr: addr.hashQr, onClose: () => setQrModalOpen(false) }),
            react_1.default.createElement("div", { className: "ad-container mb-2", style: {} },
                react_1.default.createElement("div", { className: "coinzilla", "data-zone": "C-26660bf627543e46851" })),
            react_1.default.createElement(address_transactions_container_1.AddressTransactionsContainer, { addressHash: addressHash }))));
};
exports.AddressDetailsContainer = AddressDetailsContainer;
