"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeTransactionsContainer = void 0;
const React = __importStar(require("react"));
const use_get_txs_1 = require("@/shared/block-details-container/hooks/use-get-txs");
const tx_transaction_item_1 = require("@/shared/explorer-components/tx-transaction-item");
const iso_i18n_1 = require("onefx/lib/iso-i18n");
const asset_url_1 = require("onefx/lib/asset-url");
//         TODO(dora): more tx coming in
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
function MoreTxComingIn() {
    return (React.createElement("div", { "data-selector": "channel-batching-message", style: {} },
        React.createElement("div", { "data-selector": "reload-transactions-button", className: "alert alert-info" },
            React.createElement("a", { href: "#", className: "alert-link" },
                React.createElement("span", { "data-selector": "channel-batching-count" }, "516"),
                " More transactions have come in"))));
}
function HomeTransactionsContainer() {
    var _a, _b;
    const { data, refetch, loading, error } = (0, use_get_txs_1.useGetTxs)({ first: 20, after: 0 }, { pollInterval: 5000 });
    if (loading) {
        // TODO(dora)
        return React.createElement(React.Fragment, null);
    }
    const txs = (_b = (_a = data === null || data === void 0 ? void 0 : data.transactions) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map((e) => e === null || e === void 0 ? void 0 : e.node);
    return (React.createElement("div", { className: "card card-chain-transactions" },
        React.createElement("div", { className: "card-body" },
            React.createElement("a", { className: "btn-line float-right", href: (0, asset_url_1.assetURL)("txs") }, "View All Transactions"),
            React.createElement("h2", { className: "card-title lg-card-title" },
                " ",
                (0, iso_i18n_1.t)("nav.txs")),
            error && (React.createElement("button", { "data-error-message": true, className: "alert alert-danger col-12 text-left", onClick: refetch },
                React.createElement("span", { className: "alert-link" }, (0, iso_i18n_1.t)("info.err")))),
            !(txs === null || txs === void 0 ? void 0 : txs.length) && (React.createElement("div", { "data-empty-response-message": true },
                React.createElement("div", { className: "tile tile-muted text-center", "data-selector": "empty-transactions-list" }, "There are no transactions for this chain."))),
            React.createElement("span", { "data-selector": "transactions-list", "data-transactions-path": "/recent-transactions" }, !!(txs === null || txs === void 0 ? void 0 : txs.length) &&
                txs.map((tx) => React.createElement(tx_transaction_item_1.TxTransactionItem, { key: tx === null || tx === void 0 ? void 0 : tx.hash, tx: tx }))))));
}
exports.HomeTransactionsContainer = HomeTransactionsContainer;
