"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTokenTransfer = void 0;
const queries_1 = require("@/shared/token-transfer-container/data/queries");
const client_1 = require("@apollo/client");
const useTokenTransfer = (transactionHash) => {
    const { data, loading, error, refetch } = (0, client_1.useQuery)(queries_1.queryTokenTransfer, {
        ssr: false,
        skip: !transactionHash,
        variables: { transactionHash },
    });
    return {
        transferData: data,
        transferLoading: loading,
        transferError: error,
        transferRefetch: refetch,
    };
};
exports.useTokenTransfer = useTokenTransfer;
