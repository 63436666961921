"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotFound = void 0;
const iso_i18n_1 = require("onefx/lib/iso-i18n");
const react_router_1 = require("onefx/lib/react-router");
const React = __importStar(require("react"));
const error_page_1 = require("./error-page");
function NotFound() {
    return (React.createElement(Status, { code: 404 },
        React.createElement(error_page_1.ErrorPage, { bar: (0, iso_i18n_1.t)("not_found.bar"), info: (0, iso_i18n_1.t)("not_found.info"), title: (0, iso_i18n_1.t)("not_found.title") })));
}
exports.NotFound = NotFound;
function Status({ code, children }) {
    return (React.createElement(react_router_1.Route, null, (props) => {
        if (props.staticContext) {
            props.staticContext.statusCode = code;
        }
        return children;
    }));
}
