"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGtag = void 0;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const useGtag = () => {
    const { listen } = (0, react_router_dom_1.useHistory)();
    const gaMeasurementId = (0, react_redux_1.useSelector)((state) => state.base.analytics.gaMeasurementId);
    (0, react_1.useEffect)(() => {
        return listen((location) => {
            if (!window.gtag) {
                return;
            }
            if (!gaMeasurementId) {
                console.warn("please specify your gaMeasurementId");
                return;
            }
            window.gtag("config", gaMeasurementId, { page_path: location.pathname });
        });
    }, [gaMeasurementId, listen]);
};
exports.useGtag = useGtag;
