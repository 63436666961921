"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fonts = void 0;
exports.fonts = {
    body: {},
    h1: {
        fontSize: "3rem",
        color: "#152935",
        textTransform: "capitalize",
        fontWeight: 700,
        margin: "-1.15rem 0 0 -3px",
        padding: 0,
        transition: "250ms cubic-bezier(0.5, 0, 0.1, 1)",
    },
    textBox: {
        fontSize: "18px !important",
        fontWeight: 300,
    },
    inputLabel: {
        fontSize: "14px",
        fontWeight: 700,
    },
    inputError: {
        fontSize: "12px !important",
    },
};
