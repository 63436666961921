"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryTokens = void 0;
const client_1 = require("@apollo/client");
const query_1 = require("@/shared/token-container/data/query");
const pageSize = 50;
const useQueryTokens = (querySymbol) => {
    const { data, loading, fetchMore } = (0, client_1.useQuery)(query_1.queryTokens, {
        ssr: false,
        variables: {
            after: "0",
            first: pageSize,
            symbol: querySymbol,
        },
    });
    return {
        tokensFetchMore: async (cursor, symbol) => {
            if (!cursor) {
                return;
            }
            await fetchMore({
                variables: {
                    after: cursor,
                    first: pageSize,
                    symbol,
                },
            });
        },
        tokensData: data,
        tokensLoading: loading,
    };
};
exports.useQueryTokens = useQueryTokens;
