"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAddrDetails = void 0;
const client_1 = require("@apollo/client");
const queries_1 = require("@/shared/address-details-container/data/queries");
const useGetAddrDetails = ({ hash }) => {
    const { loading, data, error, refetch } = (0, client_1.useQuery)(queries_1.queryAddressDetailsByHash, {
        ssr: false,
        variables: {
            hash,
        },
    });
    return { loading, data, error, refetch };
};
exports.useGetAddrDetails = useGetAddrDetails;
