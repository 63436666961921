"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectTokenTransfer = void 0;
const selectTokenTransfer = (resp) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = resp === null || resp === void 0 ? void 0 : resp.tokenTransfer) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map((ed) => ed === null || ed === void 0 ? void 0 : ed.node)) === null || _c === void 0 ? void 0 : _c.map((node) => ({
        transactionHash: node === null || node === void 0 ? void 0 : node.transactionHash,
        fromAddress: node === null || node === void 0 ? void 0 : node.fromAddress,
        toAddress: node === null || node === void 0 ? void 0 : node.toAddress,
        amount: node === null || node === void 0 ? void 0 : node.amount,
        tokenContractAddress: node === null || node === void 0 ? void 0 : node.tokenContractAddress,
        amountWithDecimals: node === null || node === void 0 ? void 0 : node.amountWithDecimals,
    }));
};
exports.selectTokenTransfer = selectTokenTransfer;
