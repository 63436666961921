"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryBlocks = void 0;
const client_1 = require("@apollo/client");
exports.queryBlocks = (0, client_1.gql) `
  query QueryBlocks($first: Float, $after: String) {
    blocks(first: $first, after: $after) {
      edges {
        node {
          consensus
          difficulty
          gasLimit
          gasUsed
          hash
          miner
          nonce
          number
          parentHash
          size
          timestamp
          totalDifficulty
          numTxs
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
    }
  }
`;
