"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTxs = void 0;
const quries_1 = require("@/shared/block-details-container/data/quries");
const client_1 = require("@apollo/client");
const useGetTxs = (filters, { pollInterval }) => {
    const { loading, data, error, refetch } = (0, client_1.useQuery)(quries_1.getTxs, {
        ssr: false,
        variables: {
            ...filters,
            after: String(filters.after),
        },
        pollInterval,
        ...(pollInterval
            ? {
                fetchPolicy: "no-cache",
            }
            : {}),
    });
    return { loading, data, error, refetch };
};
exports.useGetTxs = useGetTxs;
