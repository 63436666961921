"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAddr = void 0;
const client_1 = require("@apollo/client");
const queries_1 = require("@/shared/address-details-container/data/queries");
const useGetAddr = ({ hash, first, after, }) => {
    const { loading, data, error, refetch } = (0, client_1.useQuery)(queries_1.queryAddressByHash, {
        ssr: false,
        variables: {
            hash,
            first,
            after: String(after),
        },
    });
    return { loading, data, error, refetch };
};
exports.useGetAddr = useGetAddr;
