"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fullOnPalm = exports.media = exports.PALM_WIDTH = void 0;
exports.PALM_WIDTH = 575;
exports.media = {
    palm: `@media only screen and (max-width: ${exports.PALM_WIDTH}px)`,
    lap: `@media only screen and (min-width: ${exports.PALM_WIDTH}px) and (max-width: 768px)`,
    desk: "@media only screen and (min-width: 769px) and (max-width: 1280px)",
    deskWide: "@media only screen and (min-width: 1281px)"
};
exports.fullOnPalm = {
    [exports.media.palm]: {
        width: "100%"
    }
};
