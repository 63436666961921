"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryTokens = void 0;
const client_1 = require("@apollo/client");
exports.queryTokens = (0, client_1.gql) `
  query Tokens(
    $after: String
    $first: Float
    $before: String
    $last: Float
    $symbol: String
  ) {
    tokens(
      after: $after
      first: $first
      before: $before
      last: $last
      symbol: $symbol
    ) {
      edges {
        cursor
        node {
          name
          symbol
          totalSupply
          decimals
          type
          contractAddress
          skipMetadata
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
