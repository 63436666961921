"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryBlocks = void 0;
const queries_1 = require("@/shared/blks-table-container/data/queries");
const client_1 = require("@apollo/client");
const useQueryBlocks = ({ first, after, }, { pollInterval = undefined }) => {
    const { loading, data, error, refetch } = (0, client_1.useQuery)(queries_1.queryBlocks, {
        ssr: false,
        variables: {
            first,
            after: String(after),
        },
        pollInterval,
        ...(pollInterval
            ? {
                fetchPolicy: "no-cache",
            }
            : {}),
    });
    return { loading, data, error, refetch };
};
exports.useQueryBlocks = useQueryBlocks;
