"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paginationProcessTotalNumPage = void 0;
function paginationProcessTotalNumPage(withPageInfo) {
    if (!withPageInfo) {
        return 1;
    }
    const { pageInfo } = withPageInfo;
    const totalRecord = parseInt((pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor) || "20", 10) +
        parseInt((pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.startCursor) || "20", 10);
    return Math.round(totalRecord / 20) - 1;
}
exports.paginationProcessTotalNumPage = paginationProcessTotalNumPage;
